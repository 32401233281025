import { get, patch, post, del } from 'modules/api';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const MAX_ASSOCIATES = 4;
const COMPANY_TYPE_IDS_NEED_UBO = [
    50,
    51,
    52,
    53,
    55,
    56,
    107,
    108,
    109,
    111,
    113,
    114,
    115,
    116,
    140,
    141,
    145,
    32,
    33,
    34,
    35,
    36,
    37,
    40,
    41,
    42,
    43,
    54,
    59,
    62,
    171,
    64,
    65,
    66,
    67,
    70,
    71,
    73,
    75,
    76,
    77,
    1,
    3,
    4,
    5,
    105,
    168,
    178,
    117,
    121,
    122,
    24,
    25,
    26,
    27,
    167,
    169,
    146,
    149,
    152,
    190,
    13,
    14,
    15,
    16,
    17,
    18,
    160,
    132,
    133,
    134,
    135,
    136,
    137,
    7,
    9,
    106,
    193,
    22,
    49,
    191,
    194,
];

export const UBO_SUBMISSION_STATUS = {
    NEVER_SENT: 'never_sent',
    SENT: 'sent',
    SENDING_FAILED: 'sending_failed',
    REFUSED: 'refused',
    ACCEPTED: 'accepted',
};

export const UboSubmissionStatusPropTypes = PropTypes.oneOf([
    UBO_SUBMISSION_STATUS.NEVER_SENT,
    UBO_SUBMISSION_STATUS.SENT,
    UBO_SUBMISSION_STATUS.SENDING_FAILED,
    UBO_SUBMISSION_STATUS.REFUSED,
    UBO_SUBMISSION_STATUS.ACCEPTED,
]);

export const uboSubmissionStatusNotFreezed = [
    UBO_SUBMISSION_STATUS.NEVER_SENT,
    UBO_SUBMISSION_STATUS.SENDING_FAILED,
    UBO_SUBMISSION_STATUS.REFUSED,
];

export function hasCompleteKycLegalRepresentativeInfo(company, manager) {
    const legalRepr = company.legalRepresentative || { address: {} };
    return (
        (!!legalRepr.birthday || !!manager.birthday) &&
        (!!legalRepr.nationality || !!manager.nationality) &&
        ((!!legalRepr.address && !!legalRepr.address.street) ||
            (!!manager.address && !!manager.address.street))
    );
}

export function getDocumentsUploadUrl(companyId) {
    return `v2/companies/${companyId}/documents`;
}

export function getLabelsUploadUrl(companyId) {
    return `v2/companies/${companyId}/labels`;
}

export function getCompanyTypes(countryId) {
    return get(`company_types/?country=${countryId}`);
}

export function fetchCompany(companyId) {
    return get(`companies/${companyId}`);
}

export function saveCompany(companyId, payload) {
    return patch(`companies/${companyId}`, payload);
}

export const addAssociate = (companyId, payload) =>
    post(`companies/${companyId}/associate`, payload);

export const deleteAssociate = (companyId, associateId) =>
    del(`companies/${companyId}/associate/${associateId}`);

export const hasAssociateForm = ({ companyTypeId }) =>
    R.includes(companyTypeId, COMPANY_TYPE_IDS_NEED_UBO);

export const canAddAssociate = ({ associates, companyTypeId, uboSubmissionStatus }) =>
    associates.length < MAX_ASSOCIATES &&
    R.includes(companyTypeId, COMPANY_TYPE_IDS_NEED_UBO) &&
    R.includes(uboSubmissionStatus, uboSubmissionStatusNotFreezed);

export const fetchCompaniesByUser = userId => get(`users/${userId}/companies`);

export const fetchCompanies = userId => get(`users/${userId}/companies`);

export const createCompanyForAssembly = ({ assemblyId }) =>
    post(`companies/`, {
        hive: assemblyId,
    });

export const createCompanyForFarm = ({ farmId }) =>
    post(`companies/`, {
        farm: farmId,
    });
