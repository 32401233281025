import { SET_CURRENT_USER, EDIT_CURRENT_USER } from './actions';
import { LOGOUT } from '../../actions';
import * as R from 'ramda';

export const anonymousUser = {
    anonymous: true,
    enabledFeatures: [],
};

export const getAssemblyAsHost = (state, assemblyId) => {
    return R.find(R.propEq('id', assemblyId), R.pathOr([], ['user', 'hivesAsLeader'], state));
};

export const currentUserReducer = (
    state = {
        user: anonymousUser,
    },
    { type, payload }
) => {
    switch (type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                user: payload.user,
            };
        case EDIT_CURRENT_USER:
            return {
                ...state,
                user: payload(state.user),
            };
        case LOGOUT:
            return {
                ...state,
                user: anonymousUser,
            };
        default:
            return state;
    }
};
