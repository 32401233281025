import AppChannel from 'modules/channels/App';
import Backbone from 'backbone';
import { linkTo } from 'modules/utils';

import { userSelector } from './selectors';
import { userJoinAssembly } from 'api/hives';
import { getUserInfo, getRole } from 'models/users.js';
import { findLanguageBybCode, isCurrentLanguage } from 'models/locales.js';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const EDIT_CURRENT_USER = 'EDIT_CURRENT_USER';

export const setCurrentUser = user => ({
    type: SET_CURRENT_USER,
    payload: { user },
});

export const imperativeEditUser = reducer => ({
    type: EDIT_CURRENT_USER,
    payload: reducer,
});

export const joinAssembly = (assemblyId, userId) => (dispatch, getState) => {
    const previousState = getState();
    const currentUser = userSelector(previousState);
    userJoinAssembly(assemblyId, userId).then(() => {
        if (currentUser) {
            return getUserInfo().then(user => {
                dispatch(setCurrentUser(user));
            });
        }
        return null;
    });
};

export const refreshCurrentUser = () => dispatch =>
    getUserInfo().then(user => {
        return dispatch(setCurrentUser(user));
    });

export const loadCurrentUser = args => dispatch =>
    getUserInfo().then(currentUser => {
        dispatch(setCurrentUser(currentUser));
        AppChannel.vent.trigger('analytics:role:changed', getRole(currentUser));

        let homepageURL = AppChannel.reqres.request('homepage:route');
        // @note (sinewyk): sill used on google tag manager apparently for analytics purposes
        if (args && args.hasRegistered) {
            homepageURL += '?register=success';
        }

        if (!___DEV___) {
            const userLanguage = findLanguageBybCode(currentUser.locale);
            if (userLanguage && !isCurrentLanguage(userLanguage)) {
                const url = (args && args.redirectUrl) || homepageURL;
                const urlWithoutLeadingSlash = url[0] === '/' ? url.substring(1) : url;
                const locale = userLanguage.shortcut ? userLanguage.shortcut : userLanguage.code;

                !args.skipRedirection &&
                    AppChannel.commands.execute(
                        'change:locale',
                        `/${locale}/`,
                        urlWithoutLeadingSlash
                    );
                if (args.refresh) {
                    window.location.href = window.location.href + url;
                }
                return currentUser;
            }
        }
        const url = (args && args.redirectUrl) || homepageURL;
        if (url.indexOf('miniruche') !== -1 && !args.skipRedirection) {
            window.location = linkTo(url);
            return currentUser;
        }
        if (Backbone.History.started && url === Backbone.history.getFragment()) {
            !args.skipRedirection && Backbone.history.loadUrl();
            return currentUser;
        }
        !args.skipRedirection &&
            Backbone.history.navigate(url, {
                trigger: true,
            });
        if (args.refresh) {
            window.location.href = window.location.href + url;
        }

        return currentUser;
    });
