import Utils from 'modules/utils';
const { quantityString, pricePerQuantity } = Utils;
import { Translator } from 'modules/i18n';
import { get, post, patch, del, put } from 'modules/api';
import { join } from 'modules/utils/string';

import * as R from 'ramda';

export const LOGISTIC_MODE = {
    DROP_OFF: 'drop_off',
    SHIPPING: 'shipping',
};
export const STOCK_UNLIMITED = 'unlimited';

export function getCountNumber(offer) {
    return parseInt(offer.count, 10) || 1;
}

export function getTotalQuantityAmount(offer) {
    return offer.quantity?.amount * getCountNumber(offer);
}

export function getFormattedQuantity(offer, { strategy, locale } = {}) {
    const quantity = offer.quantity;
    const quantityMax = offer.quantityMax;
    const count = offer.count;

    switch (strategy) {
        case 'weight':
            if (!quantity) {
                return '';
            }
            if (quantityMax && quantityMax.amount) {
                return `${quantityString(quantity, quantity.unit)}-${quantityString(
                    quantityMax,
                    quantityMax.unit
                )}`;
            }
            return quantityString(quantity, quantity.unit);
        case 'unit':
            return Translator.transChoice(locale, 'offers.units', getCountNumber(offer), {
                '%unitsCount%': count,
            });
        case 'both':
            if (!quantity) {
                return '';
            }
            if (count > 1) {
                return `${count} × ${quantityString(quantity, quantity.unit)}`;
            }
            return quantityString(quantity, quantity.unit);
        default:
            return null;
    }
}

export function getFormattedQuantityEquivalent(offer, { strategy, locale, piEnabled = false }) {
    const quantity = offer.quantity;
    const count = offer.count;

    if (strategy === 'weight' && count > 1) {
        return Translator.transChoice(locale, 'offers.units', getCountNumber(offer), {
            '%unitsCount%': count,
        });
    }

    if (strategy === 'unit' && quantity && quantity.amount) {
        return piEnabled
            ? quantityString(quantity)
            : Translator.trans(locale, 'offers.totalling', {
                  '%quantity%': quantityString(quantity),
              });
    }

    return null;
}

export function getFormattedPricePerQuantity(offer, { strategy, locale }) {
    const quantity = offer.quantity;
    const quantityMax = offer.quantityMax;
    const price = offer.price;

    if (quantity === undefined) {
        return null;
    }

    switch (strategy) {
        case 'weight':
            if (quantityMax && quantityMax.amount) {
                return Translator.trans(locale, 'global.priceRange', {
                    '%min%': pricePerQuantity(price, quantityMax).split('/')[0],
                    '%max%': pricePerQuantity(price, quantity),
                });
            }
            return pricePerQuantity(price, quantity);

        case 'unit':
            if (quantity && quantity.amount) {
                return pricePerQuantity(price, {
                    unit: offer.quantity.unit,
                    amount: quantity.amount,
                });
            }
            return null;

        case 'both':
            return pricePerQuantity(price, {
                unit: offer.quantity?.unit,
                amount: getTotalQuantityAmount(offer),
            });

        default:
            return null;
    }
}

export function isReadyForSale(offer, strategy) {
    switch (strategy) {
        case 'weight':
            return !!(
                offer.isAvailable &&
                offer.price &&
                offer.price.amount &&
                offer.quantity &&
                offer.quantity.amount
            );
        case 'unit':
            return !!(offer.isAvailable && offer.price && offer.price.amount && offer.count);
        case 'both':
            return !!(
                offer.isAvailable &&
                offer.price &&
                offer.price.amount &&
                offer.quantity &&
                offer.quantity.amount &&
                offer.count
            );
        default:
            throw new Error('Strategy should be "weight | unit | both"');
    }
}

export function setAvailability(offer, { isAvailable }) {
    return patch(`products/${offer.productId}/offers/${offer.id}`, {
        isAvailable,
        reference: offer.reference,
    });
}

export function patchOffer(offer, newReference, isBulk) {
    return patch(`products/${offer.productId}/offers/${offer.id}`, {
        isAvailable: offer.isAvailable,
        reference: newReference,
        isBulk,
    });
}

export const indexMaxLimitsByAssemblyAndDistribution = R.pipe(
    R.map(R.evolve({ distributions: R.indexBy(R.prop('uuid')) })),
    R.indexBy(R.prop('hiveUuid'))
);

export const getMaxLimitDistributions = assemblyMaxLimit => {
    return R.sortBy(R.prop('distributionDate'), R.values(assemblyMaxLimit.distributions));
};

export function getUniqueMaxLimitValue(distributions) {
    const values = R.compose(R.uniq, R.reject(R.isNil), R.pluck('maxLimit'))(distributions);
    return values.length === 1 ? values[0] : null;
}

export const setDistributionMaxLimit = (assemblyUuid, distributionUuid, newMaxLimit) =>
    R.assocPath([assemblyUuid, 'distributions', distributionUuid, 'maxLimit'], newMaxLimit);

export const setAssemblyDefaultMaxLimit = (assemblyUuid, newDefaultMaxLimit) =>
    R.assocPath([assemblyUuid, 'defaultMaxLimit'], newDefaultMaxLimit);

function buildOfferLimitDistributionCommand(offerUuid, distributionUuid, maxLimit) {
    const payload = {
        offerUuid,
        distributionUuid,
    };
    return {
        command:
            maxLimit !== null
                ? 'capOfferAvailableQuantityForDistribution'
                : 'doNotCapOfferAvailableQuantityForDistribution',
        payload: maxLimit !== null ? R.assoc('maximum', maxLimit, payload) : payload,
    };
}

function buildOfferLimitAssemblyCommands(offerUuid, assemblyUuid, maxLimit) {
    const payload = {
        offerUuid,
        hiveUuid: assemblyUuid,
    };

    return [
        {
            command:
                maxLimit !== null
                    ? 'capOfferDefaultAvailableQuantityForDistributionsOfHive'
                    : 'doNotCapOfferDefaultAvailableQuantityForDistributionsOfHive',
            payload: maxLimit !== null ? R.assoc('maximum', maxLimit, payload) : payload,
        },
        {
            command: 'capOfferAvailableQuantityForAllDistributionsOfHiveWithHiveDefault',
            payload,
        },
    ];
}

export function getMaxLimitsChangesCommands(offer, maxLimitsChanges) {
    const offerUuid = offer.uuid;

    const assemblyCommands = R.pipe(
        R.mapObjIndexed((value, key) => R.assoc('assemblyUuid', key, value)),
        R.values,
        R.filter(R.has('defaultMaxLimit')),
        R.map(({ assemblyUuid, defaultMaxLimit }) =>
            buildOfferLimitAssemblyCommands(offerUuid, assemblyUuid, defaultMaxLimit)
        ),
        R.flatten
    )(maxLimitsChanges);

    const distributionCommands = R.pipe(
        R.pluck('distributions'),
        R.values,
        R.mergeAll,
        R.mapObjIndexed((distribution, distributionUuid) =>
            buildOfferLimitDistributionCommand(offerUuid, distributionUuid, distribution.maxLimit)
        ),
        R.values
    )(maxLimitsChanges);

    return assemblyCommands.concat(distributionCommands);
}

export function saveMaxLimitsChanges(offer, maxLimitsChanges) {
    const commands = getMaxLimitsChangesCommands(offer, maxLimitsChanges);
    return post('offers/commands', { commands });
}

export function getMaxLimitByDistribution(offer) {
    return get(`offers/${offer.id}/maxLimitPerDistribution`).then(
        indexMaxLimitsByAssemblyAndDistribution
    );
}

export const hasStockAvailable = R.propSatisfies(
    R.either(R.equals('unlimited'), R.lt(0)),
    'availableStock'
);

export const replacePriceOffer = (offerId, payload) =>
    post(`offers/${offerId}/replace-to-change-price`, payload);

export const deleteOffer = (productId, offerId) => del(`products/${productId}/offers/${offerId}`);

export const expirePromotionOffer = offerId => post(`offers/${offerId}/expire-promotion`);

export const addOfferInProduct = (productId, payload) =>
    post(`products/${productId}/offers`, payload);

export const isTresholdReached = offer =>
    offer.thresholdOffer !== null && offer.availableStock <= offer.thresholdOffer;

export const updateAvailabilityInAssemblies = ({
    offerId,
    hivesToBeDelivered,
    hivesNotToBeDelivered,
}) =>
    put(`offers/${offerId}/updateAvailability`, {
        hivesToBeDelivered,
        hivesNotToBeDelivered,
    });

export const getFormattedQuantities = (offers, type, i18n, trans) => {
    const offersQuantities = offers.map(offer =>
        getFormattedQuantity(offer, { strategy: type.quantityStrategy, locale: i18n })
    );
    return join(offersQuantities, {
        separator: ', ',
        lastSeparator: ` ${trans('global.or')} `,
    });
};

export const isSameFormattedQuantities = (offers, type, i18n) => {
    return offers.every(
        offer =>
            getFormattedQuantity(offer, { strategy: type.quantityStrategy, locale: i18n }) ===
            getFormattedQuantity(offers[0], { strategy: type.quantityStrategy, locale: i18n })
    );
};
