import { post } from 'modules/api';
import AppChannel from 'modules/channels/App';

export function appLogin(
    email,
    password,
    remember,
    redirectUrl,
    anonymousBasketUuid = null,
    distributionId = null,
    refresh = false
) {
    AppChannel.commands.execute('login', {
        username: email,
        password,
        remember,
        redirectUrl,
        anonymousBasketUuid,
        distributionId,
        refresh,
    });
}

export function claimAnonymousBasket(basketUuid) {
    return post(`baskets/${basketUuid}/claim`);
}
