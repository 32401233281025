import { some, compose } from 'underscore';
import { uppercaseInitalWithDot, titleCase, toLowerCase } from 'modules/utils/string';
import { del, patch, post, put, get } from 'modules/api';
import { deleteUserInHive } from 'api/hives';
import * as R from 'ramda';
import { dispatchLogout } from 'modules/utils/windowReduxStore.js';
import Impersonate from 'modules/impersonate';
import * as OAuth from 'modules/oauth';
import { fetchCompaniesByUser } from 'models/companies.js';
import { hasCompleteKycLegalRepresentativeInfo } from 'models/companies.js';

/**
 * Is user leader of assembly
 * @param  {Object} user
 * @param  {number} assemblyId
 * @return {boolean}
 */
export const isHostOfAssembly = (user, assemblyId) =>
    some(user.hivesAsLeader, assembly => assembly.id === assemblyId);

export function formatFullName(
    { firstName, lastName, anonymous = false, anonymized = false },
    { shortenedFirstName = false, shortenedLastName = false, trans = key => key } = {}
) {
    if (anonymous) {
        return '';
    }

    if (anonymized) {
        return trans('global.user.anonymized');
    }

    const formattedFirstName = shortenedFirstName
        ? uppercaseInitalWithDot(firstName)
        : compose(titleCase, toLowerCase)(firstName);
    const formattedLastName = shortenedLastName
        ? uppercaseInitalWithDot(lastName)
        : compose(titleCase, toLowerCase)(lastName);

    return `${formattedFirstName} ${formattedLastName}`;
}

export function getUserPhotoEndpoint() {
    return `${process.env.API_ROOT}/users/photos`;
}

export function deleteUserPhoto() {
    return del('users/photos');
}

export function acceptDocuments(userId, isProfessional) {
    const acceptedDocuments = { hasAcceptedTOS: true };
    if (isProfessional) {
        acceptedDocuments.hasAcceptedSCU = true;
    }
    return patch(`users/${userId}`, acceptedDocuments);
}

export function personalDocumentsRequest(userId) {
    return post(`users/${userId}/data_recovery`);
}

export function register(data) {
    return post('users/', { ...data, role: data.role === 'host' ? 'leader' : data.role });
}

export function isMemberOfAssembly(assemblyId, user) {
    if (user.anonymous) {
        return false;
    }

    return (
        user &&
        user.hivesAsMember &&
        user.hivesAsMember.some(assembly => assembly.id === assemblyId)
    );
}

export function isProvidingAssembly(assemblyId, user) {
    if (user.anonymous) {
        return false;
    }

    return (
        user &&
        user.hivesAsFarmer &&
        user.hivesAsFarmer.some(assembly => assembly.id === assemblyId)
    );
}

export const askForNewPassword = email => post(`password_reset/`, { email });

export const resetPassword = (userId, token, password) =>
    put(`password_set/`, { userId, token, password });

export const createLeaderProfile = userId =>
    post(`users/${userId}/leader_profile`, {
        blob: '{}',
    });

export const isEligibleForPro = user => user && user.phone && user.hasAcceptedSCU;

export const getAssembliesThatUserNotHosting = user =>
    user ? R.reject(assembly => assembly.leader.id === user.id)(user.hivesAsMember || []) : [];

export const updateLeaderProfile = (userId, payload) =>
    patch(`users/${userId}/leader_profile`, payload);

export const updateUser = (userId, payload) => patch(`users/${userId}`, payload);

export const createFarm = () => post('farms/');

export const getRole = user => {
    if (user.isLeader === true) {
        return 'leader';
    }
    if (user.isFarmer === true) {
        return 'farmer';
    }
    if (user.isMember === true) {
        return 'member';
    }
    return 'visitor';
};

export const isProfessional = user => {
    return R.includes(getRole(user), ['leader', 'farmer']);
};

export const getUserInfo = () => get('me/');

export const isLeaderOfAssembly = ({ assemblyId, user }) =>
    !R.isNil(user) &&
    !R.isNil(user.hivesAsLeader) &&
    user.hivesAsLeader.some(assembly => assembly.id === assemblyId);

export const clearUserAndToken = () => {
    OAuth.clear();
    Impersonate.clear();
    dispatchLogout();
};

const mayNeedToFillKycProfessionalProfile = user =>
    !!user &&
    isProfessional(user) &&
    (!user.birthday || !user.nationality || !user.address || !user.address.street);

const hasMissingKycInfo = (userCompanies, user) => {
    if (!userCompanies) {
        return false;
    }
    return userCompanies.some(company => !hasCompleteKycLegalRepresentativeInfo(company, user));
};

export const mustFillKycProfessionalProfile = user =>
    new Promise(resolve => {
        if (!mayNeedToFillKycProfessionalProfile(user)) {
            return resolve(false);
        }
        return fetchCompaniesByUser(user.id)
            .then(data => data.companies)
            .then(companies => resolve(hasMissingKycInfo(companies, user)));
    });

export const getRoleInAssembly = ({ user, assemblyId }) => {
    if (isLeaderOfAssembly({ assemblyId, user })) {
        return 'leader';
    }
    if (isMemberOfAssembly(assemblyId, user)) {
        return 'member';
    }
    return 'visitor';
};

export const fetch10YearsData = ({ userId }) => get(`users/${userId}/ten-years-history`);

export const leaveAssembly = ({ userId, assemblyId }) => deleteUserInHive(userId, assemblyId);

export const asMultiRole = ({ user }) => {
    const getTrueProps = R.compose(R.length, R.filter(R.equals(true)), R.values);
    return getTrueProps(R.pick(['isLeader', 'isFarmer', 'isMember'], user)) >= 2;
};

export const isMemberOnly = ({ user }) => user.isMember && !user.isLeader && !user.isFarmer;
