import mapBoxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import * as R from 'ramda';

export const MAPBOX_APP_KEY = 'pk.eyJ1IjoibHJxZG8iLCJhIjoibzBPTzE0ZyJ9.-udp6yXoZGNOC7u3wF4GkQ';
const SEARCH_IN_COUNTRIES = ['FR', 'IT', 'DE', 'CH', 'NL', 'BE', 'ES', 'MQ', 'GP', 'RE'];

const geocodingService = mapBoxGeocoding({ accessToken: MAPBOX_APP_KEY });

const getCoordinates = hit =>
    hit && hit.geometry && hit.geometry.coordinates
        ? {
              _geoloc: {
                  lat: hit.geometry.coordinates[1],
                  lng: hit.geometry.coordinates[0],
              },
          }
        : { _geoloc: { lat: null, lng: null } };

const extractContextInfo = hit => {
    const infos = {};
    R.forEach(context => {
        if (context.id.match(/postcode/)) {
            infos.postcode = context.text;
        }
        if (context.id.match(/country/)) {
            infos.country = context.text;
            infos.country_code = context.short_code;
        }
        if (context.id.match(/place/)) {
            infos.city = context.text;
        }
    }, hit.context);
    return {
        ...infos,
        ...getCoordinates(hit),
    };
};

export const getAddressSuggestions = query =>
    geocodingService
        .forwardGeocode({
            query: query.query,
            limit: 5,
            language: [query.language],
            countries: SEARCH_IN_COUNTRIES,
        })
        .send()
        .then(({ body }) =>
            R.map(feature => ({
                ...feature,
                ...extractContextInfo(feature),
            }))(body.features)
        );

export const splitAddressComponents = suggestion => ({
    street: suggestion.text && `${suggestion.address} ${suggestion.text}`,
    city: suggestion.city && suggestion.city,
    zipCode: suggestion.postcode && suggestion.postcode,
});

export const geocode = (query, countries = []) =>
    getAddressSuggestions({ query, hitsPerPage: 1, countries })
        .then(hits => {
            const firstSuggestion = hits[0];
            return {
                address: firstSuggestion.name,
                coordinates: {
                    latitude: firstSuggestion._geoloc.lat,
                    longitude: firstSuggestion._geoloc.lng,
                },
                postcode: firstSuggestion.postcode,
                countryCode: firstSuggestion.country_code,
            };
        })
        .catch(() => null);
